import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import { Grid, Container } from 'components/Grid';
import Hero from 'components/Hero';

import { PageLink } from './styles';

export const LegalPageTemplate = ({ page, posts }) => {
  return (
    <>
      <Hero content={page.hero} />
      <Grid>
        <Container mb="20px">
          {posts.map(item => {
            const post = item.node;
            return (
              <PageLink to={post.fields.slug} key={post.id}>
                {post.frontmatter.title}
              </PageLink>
            );
          })}
        </Container>
      </Grid>
    </>
  );
};

// LegalPageTemplate.propTypes = {
//   title: PropTypes.string
// };

const LegalPage = ({ data }) => {
  const { frontmatter: page } = data.page;
  const { edges: posts } = data.posts;

  return (
    <Layout content={page}>
      <LegalPageTemplate page={page} posts={posts} />
    </Layout>
  );
};

LegalPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default LegalPage;

export const legalPageQuery = graphql`
  query LegalPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        page {
          title
          background {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        hero {
          heading
          description
        }
        cta {
          heading
          description
          buttonText
          buttonPath
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___hero___date] }
      filter: { frontmatter: { templateKey: { eq: "legal-post" } } }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
