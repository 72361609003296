import React from "react";

import Button from "components/Button";

import { Wrapper, Inner, Heading, Description } from "./styles";

const Hero = ({ content, shade, ...props }) => {
  return (
    <Wrapper>
      <Inner {...props}>
        <Heading shade={shade}>{content.heading}</Heading>
        {content.description && (
          <Description shade={shade}>{content.description}</Description>
        )}
        {content.buttonPath && content.buttonText && (
          <Button mt="40px" shade={shade} to={content.buttonPath}>
            {content.buttonText}
          </Button>
        )}
      </Inner>
    </Wrapper>
  );
};

export default Hero;
