import styled from "styled-components";
import styledMap from "styled-map";
import { space } from "styled-system";

import theme from "styles/theme";
import { Grid } from "components/Grid";
import { H1, H3 } from "components/Text";

export const Wrapper = styled.div`
  padding-top: 65px;
  text-align: center;
`;

export const Inner = styled(Grid)`
  padding-top: 70px;
  padding-bottom: 90px;

  ${space};

  @media (max-width: ${theme.size.mobile}) {
    padding-top: 50px;
    padding-bottom: 70px;

    ${space};
  }
`;

export const Heading = styled(H1)`
  margin: 0 auto 20px;
  max-width: 700px;

  color: ${styledMap("shade", {
    light: "white",
    default: theme.color.indigo
  })};
`;

export const Description = styled(H3)`
  margin: 0 auto;
  max-width: 900px;
  font-weight: ${theme.font.weight.regular};

  @media (max-width: ${theme.size.mobile}) {
    font-size: ${theme.font.size.upsilon};
  }

  color: ${styledMap("shade", {
    light: "white",
    default: theme.color.gray2
  })};
`;
