import styled from "styled-components";

import theme from "styles/theme";

import Button from "components/Button";

export const PageLink = styled(Button)`
  min-height: 280px;
  justify-content: flex-start;
  align-items: flex-end;
  width: calc(25% - 10px);
  margin: 5px;
  padding: 40px;
  display: flex;
  font-size: ${theme.font.size.theta};

  @media (max-width: ${theme.size.desktop}) {
    min-height: 180px;
    width: calc(50% - 10px);
  }

  @media (max-width: ${theme.size.tablet}) {
    min-height: 140px;
    padding: 25px;
    font-size: ${theme.font.size.upsilon};
  }

  @media (max-width: ${theme.size.mobile}) {
    width: 100%;
    min-height: 0;
  }
`;
